import React, { useEffect } from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import { trackGoal } from '../utilities/plausible';

const NewsletterAbmeldungPage = (props) => {
  const title = 'Newsletter Abmeldung';

  useEffect(() => {
    trackGoal('Newsletter: Unsubscribed');
  }, []);

  return (
    <Layout path={props.location.pathname}>
      <MetaData title={title} />

      <ContentSection>
        <h1>{title}</h1>

        <p className="mt-30">
          Schade! Du hast dich erfolgreich von unserem Newsletter abgemeldet.
        </p>
      </ContentSection>
    </Layout>
  );
};

export default NewsletterAbmeldungPage;
